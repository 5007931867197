import { useQuery } from "@vue/apollo-composable";
import dayjs from "dayjs";
import gql from "graphql-tag";
import { isEmpty } from "lodash";
import { withQuery } from "ufo";
import type { FieldInput } from "../types/fields";

export type ExportColumn = {
  name: string;
  key: string;
  default: boolean;
};

export enum DateRange {
  TODAY = "today",
  CURRENT_MONTH = "current-month",
  LAST_7_DAYS = "last-7-days",
  LAST_MONTH = "last-month",
  ALL = "all",
  CUSTOM = "custom",
}

export const dateRanges = () => {
  const lastMonth = dayjs().subtract(1, "month");

  const ranges = [
    { title: "Today", dates: [dayjs()], value: DateRange.TODAY },
    {
      title: "Current month",
      dates: [dayjs().startOf("month"), dayjs()],
      value: DateRange.CURRENT_MONTH,
    },
    {
      title: "Last 7 days",
      dates: [dayjs().subtract(7, "days"), dayjs().subtract(1, "days")],
      value: DateRange.LAST_7_DAYS,
    },
    {
      title: "Last month",
      dates: [lastMonth.clone().startOf("month"), lastMonth.endOf("month")],
      value: DateRange.LAST_MONTH,
    },
    { title: "All", value: DateRange.ALL },
    { title: "Custom", value: DateRange.CUSTOM },
  ];

  return ranges.map((range) => ({
    ...range,
    subtitle: range.dates?.map((d) => d.format("D MMM")).join("—"),
  }));
};

export const timezones = () => {
  const timezoneOptions = Intl.DateTimeFormat().resolvedOptions();
  const getOffset = () => {
    const timezoneOffset = new Date().getTimezoneOffset();
    const offset = Math.abs(timezoneOffset);
    const offsetOperator = timezoneOffset < 0 ? "+" : "-";
    const offsetHours = Math.floor(offset / 60)
      .toString()
      .padStart(2, "0");
    const offsetMinutes = Math.floor(offset % 60)
      .toString()
      .padStart(2, "0");

    return `${offsetOperator}${offsetHours}:${offsetMinutes}`;
  };

  const timezone = timezoneOptions.timeZone;
  const offset = getOffset();

  return [
    {
      title: `${timezone} (UTC${offset})`,
      value: timezone,
    },
    // { title: `GTM+10:30 (UTC+10:30)`, value: timezoneOptions.timeZone },
    { title: "UTC", value: "UTC" },
  ];
};

export const useExport = (
  name: string,
  options?: { defaultDateRange?: DateRange }
) => {
  type ExportIntent = {
    url: string;
    session: string;
    expiry: number;
    columns: ExportColumn[];
    args: FieldInput[];
  };

  const loaded = ref(false);
  const enabled = ref(false);
  const doingExport = ref(false);
  const gettingExport = ref(false);
  const exportSuccess = ref(false);
  const exportUrl = ref<string>();
  const input = ref({
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    date: options?.defaultDateRange || DateRange.TODAY,
    columns: [] as ExportColumn[],
    customDate: [] as string[],
    args: {} as Record<string, string>,
  });
  const intent = reactive<ExportIntent>({
    url: "",
    session: "",
    expiry: 0,
    columns: [],
    args: [],
  });

  const columns = computed((): ExportColumn[] => intent.columns);
  const args = computed(() => intent.args);
  const isCustomDate = computed(() => input.value.date == DateRange.CUSTOM);
  const customDateText = computed(() => input.value.customDate.join(" ~ "));
  const valid = computed(() => {
    if (!input.value.columns.length) {
      return false;
    }
    if (isCustomDate.value) {
      return input.value.customDate.length > 1;
    }
    return true;
  });
  const selectAllColumns = computed({
    set(value: boolean) {
      input.value.columns = value
        ? intent.columns
        : intent.columns.filter((col) => col.default);
    },
    get() {
      return input.value.columns.length === intent.columns.length;
    },
  });

  const intentQuery = useQuery<{
    wsExportIntent: ExportIntent;
  }>(
    gql`
      query ($name: String!) {
        wsExportIntent(name: $name) {
          url
          session
          expiry
          args
          columns {
            name
            key
            default
          }
        }
      }
    `,
    { name },
    () => ({ enabled: enabled.value })
  );

  let client: XMLHttpRequest | undefined;

  intentQuery.onResult((result) => {
    loaded.value = true;
    const data = result.data?.wsExportIntent;
    if (data) {
      intent.url = data.url;
      intent.session = data.session;
      intent.expiry = data.expiry;
      intent.columns = data.columns;
      intent.args = data.args;
      if (!input.value.columns.length) {
        input.value.columns = data.columns.filter((column) => column.default);
      }
    }
  });

  const getDate = () => {
    if (input.value.date === DateRange.CUSTOM) {
      return input.value.customDate;
    } else {
      const date = dateRanges().find(
        (entry) => entry.value == input.value.date
      );
      return date?.dates?.map((d) => d.format("YYYY-MM-DD"));
    }
  };

  const doExport = async () => {
    if (!loaded.value) {
      return;
    }

    if (isExpired(intent.expiry)) {
      await intentQuery.refetch();
    }

    const query: any = {
      timezone: input.value.timezone,
      fields: input.value.columns.map((column) => column.key).join(","),
    };
    const date = getDate();
    if (date) {
      query.date = date.join(",");
    }
    if (input.value.args && !isEmpty(input.value.args)) {
      query.args = input.value.args;
    }

    return new Promise<boolean>((resolve) => {
      // const exportUrl = "?filter[name]=John&fields=id,email"
      exportUrl.value = withQuery(intent.url, query);

      exportSuccess.value = false;
      doingExport.value = true;
      gettingExport.value = true;

      client = new XMLHttpRequest();
      client.responseType = "blob";
      client.addEventListener("load", () => {
        const ok = client!.status == 200;
        exportSuccess.value = ok;
        gettingExport.value = false;

        if (ok) {
          const fileName =
            client
              ?.getResponseHeader("Content-Disposition")
              ?.split("filename=")[1] || name + ".xlsx";

          const link = document.createElement("a");
          link.href = URL.createObjectURL(client!.response);
          link.download = fileName;
          link.click();

          // window.location.assign(url.value!)
        }

        resolve(ok);
      });
      client.addEventListener("error", () => {
        exportSuccess.value = false;
        gettingExport.value = false;
        resolve(false);
      });
      client.open("GET", exportUrl.value);
      client.send();
    });
  };

  const getIntent = () => {
    if (!loaded.value) {
      enabled.value = true;
    }
  };

  const cancel = () => {
    client?.abort();
    client = undefined;
    doingExport.value = false;
  };

  const close = () => {
    doingExport.value = false;
  };

  return {
    loadingIntent: intentQuery.loading,
    valid,
    input,
    columns,
    args,
    isCustomDate,
    customDateText,
    selectAllColumns,
    doingExport,
    gettingExport,
    exportSuccess,
    exportUrl,
    doExport,
    getIntent,
    cancel,
    close,
  };
};
